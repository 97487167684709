import React, { Component } from "react";
import {
    Row, Col, Card, CardBody, CardTitle, Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Media,
    Table,
} from "reactstrap";
import { Link } from "react-router-dom";
import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import moment from "moment";
import image from "../../assets/images/edit.png";
import { MDBDataTable, MDBCol, MDBTableBody, MDBTableHead } from "mdbreact";
import Searchicon from "../../assets/images/dashboardimages/search.png";
import createDOMPurify from 'dompurify';
const DOMPurify = createDOMPurify(window)
class Partners extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            partnersList: [],
            searchInput: ""
        };
    }
    seachBarHandler = (e) => {
        this.setState({ ...this.state, searchInput: e.target.value });
    };
    componentDidMount() {
        Notiflix.Loading.Init({
            svgColor: "#777f80",
        });

        PostApiCall.postRequest(
            {
                WhereClause: ''
            },
            "GetPartners"
        ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    this.setState({
                        data: obj.data,
                    })
                    Notiflix.Loading.Remove();
                }
            }))
        PostApiCall.postRequest(
            {
                WhereClause: ''
            },
            "GetPartnersList"
        ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    this.setState({
                        partnersList: obj.data,
                    })
                    Notiflix.Loading.Remove();
                }
            }))
    }
    render() {
        const data = {
            columns: [
                {
                    label: "S.No.",
                    field: "serial",
                    sort: "disabled",
                    width: 75,
                },
                {
                    label: "Company Name",
                    field: "name",
                    sort: "disabled",
                    width: 600,
                },
                {
                    label: "Show on website",
                    field: "status",
                    sort: "disabled",
                    width: 200,
                },
                // {
                //     label: "Action",
                //     field: "action",
                //     sort: "disabled",
                //     width: 100,
                // },
            ],
            rows: this.state.partnersList.filter((data) => {
                if (this.state.searchInput == "") {
                    return data;
                }
                if (
                    this.state.searchInput !== "" &&
                    data.fld_name
                        .toLowerCase()
                        .includes(this.state.searchInput.toLowerCase())
                ) {
                    return data;
                }
                if (
                    this.state.searchInput !== "" &&
                    data.fld_status
                        .toLowerCase()
                        .includes(this.state.searchInput.toLowerCase())
                ) {
                    return data;
                }
            }).map((data, i) => {
                return {
                    serial: (i + 1),
                    name: data.fld_name,
                    status: data.fld_status,
                    // action: (
                    //     <i
                    //         className="fas fa-edit btn"
                    //         style={{ fontSize: "15px", marginTop: "-11px" }}
                    //         onClick={() => {
                    //             this.setState({
                    //                 name: data.fld_name,
                    //                 Status: data.fld_status,
                    //                 PartnerListId: data.fld_id,
                    //             })
                    //         }}
                    //     ></i>
                    // ),
                };
            }),
        };
        return (
            <React.Fragment>
                <Card className="mini-stats-wid">
                    <CardBody>
                        <Row>
                            {/* partners */}
                            <Col md="12">
                                <div className="d-flex justify-content-between px-3">
                                    <h4 className="mb-0 productTitle">
                                        Partners
                                    </h4>
                                    <Link to={`/partners`} className="d-flex justify-content-end mb-2">
                                        <img src={image} className="edit-img" />
                                    </Link>
                                </div>
                                <hr />
                                <div className="row mb-4">
                                    {this.state.data.length != 0 ? this.state.data.map((data) => {
                                        return (
                                            <>
                                                <div className="col-12" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.fld_description) }} />

                                            </>
                                        )
                                    }) : ""}
                                </div>
                            </Col>
                            <div className="col-12">
                                <h4>List of partners</h4>
                                <MDBCol
                                    md="12"
                                    style={{
                                        marginBottom: "15px",
                                        marginTop: "15px",
                                    }}
                                    className="px-0"
                                >
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span
                                                className="input-group-text"
                                                id="basic-addon1"
                                            >
                                                <span className="">
                                                    <img
                                                        src={Searchicon}
                                                        alt="block user"
                                                        className="btnicons"
                                                    ></img>
                                                </span>
                                            </span>
                                        </div>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Type the search phrase you wish to search within the grid"
                                            aria-label="Search"
                                            aria-describedby="basic-addon1"
                                            onChange={(e) => this.seachBarHandler(e)}
                                            value={this.state.searchInput}
                                        />
                                    </div>
                                </MDBCol>
                                <MDBDataTable
                                    hover
                                    // scrollY
                                    striped
                                    bordered
                                    data={data}
                                    seachTop={false}
                                    entriesOptions={[10, 25, 50, 100]}
                                >
                                    <MDBTableHead columns={data.columns} />
                                    <MDBTableBody rows={data.rows} />
                                </MDBDataTable>
                            </div>
                        </Row>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}
export default Partners;
