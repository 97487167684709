import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, Media } from "reactstrap";
// Pages Components
import WelcomeComp from "./WelcomeComp";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//i18n
import { withNamespaces } from "react-i18next";
import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import Contact from "./Contact";
import Partners from "./Partners";
import { Link } from "react-router-dom";
import image from "../../assets/images/edit.png";
import createDOMPurify from 'dompurify';
import About from "./About";
const DOMPurify = createDOMPurify(window)
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      TablesRecords: [],
      OrdersCount: [],
      TotalSales: [],
      CustomerData: [],
      OrderData: [],
      ProductData: [],
      data: []
    };
    this.togglemodal.bind(this);
  }
  togglemodal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };
  componentDidMount() {
    Notiflix.Loading.Dots("Please wait...");
    PostApiCall.postRequest(
      {
        whereClause: ""
      },

      "GetServices"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          console.log(obj.data)
          this.setState({
            data: obj.data
          })
        }
        Notiflix.Loading.Remove();
      })
    );
  }
  render() {
    return (
      <React.Fragment>
        {/* <WelcomeComp /> */}
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs breadcrumbItem={"Dashboard"} urlPath={"/dashboard"} />
            <Row className="px-2">
              <Col xl="12">
                <About />
              </Col>
              <Col xl="12">
                <Row>
                  {/* services */}
                  <Col md="12">
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex justify-content-between px-3">
                          <h4 className="mb-0 productTitle">
                            Services
                          </h4>
                          <Link to={`/services`} className="d-flex justify-content-end mb-2">
                            <img src={image} className="edit-img" />
                          </Link>
                        </div>
                        <hr />
                        <div className="row">
                          {this.state.data.length != 0 ? this.state.data.map((data) => {
                            return (
                              <>
                                <div className="col-12 col-lg-12">
                                  <Card style={{ height: "95%" }}>
                                    <CardBody className="row">
                                      <div className="col-12 col-sm-2 mb-3">
                                        <img src={data.fld_img} style={{ width: "200px", height: "200px" }} ></img>
                                      </div>
                                      <div className="col-12 col-sm-10">
                                        <h4>{data.fld_heading}</h4>
                                        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.fld_description) }} />
                                      </div>
                                    </CardBody>
                                  </Card>
                                </div>
                              </>
                            )
                          }) : ""}
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="px-2">
              <Col xl="12">
                <Partners />
              </Col>
              <Col xl="12">
                <Contact />
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default withNamespaces()(Dashboard);
