
import React from "react";
import { Container, Row, Col } from "reactstrap";
import { version } from '../../../package.json';

const Footer = () => {
    return (
        <React.Fragment>
            <footer className="footer" style={{ height: "73px" }}>
                <Container fluid={true}>
                    <Row className="justify-content-between align-items-center">
                        <div className="col-6 col-sm-5">
                            Powered by {" "}
                            <a href="http://globaltrendz.com/" style={{ color: '#777f80' }} target="_blank">
                                GLOBAL TRENDZ
                            </a>
                        </div>
                        <div className="d-flex footer-content col-6 col-sm-7">
                            <p className="mt-0"><strong>V&nbsp;</strong>{version}</p>
                        </div>
                    </Row>
                </Container>
            </footer>
        </React.Fragment>
    );
};

export default Footer;
