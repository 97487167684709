import React, { Component } from 'react';

class Breadcrumbs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Details: [],
            Logintime: '',
            Email: "",
        }
    }
    componentDidMount() {
        var login = localStorage.getItem('LoginDetail');
        var details = JSON.parse(login)
        // //console.log(details)


        var lastlogin = localStorage.getItem('lastlogin');
        var LastDateTime = JSON.parse(lastlogin)

        // //console.log(LastDateTime)
        this.setState({
            Details: details[0],
            Logintime: LastDateTime,
            Email: details[0].fld_email
        })
    }

    render() {
        return (
            <React.Fragment>
                <div className="row px-2">
                    <div className="col-md-6">
                        <div>
                            <ul className="breadcrumbs">
                                <li style={{ fontWeight: "900" }}><a href="varadatechsolutions.in">Varadatechsolutions.in</a></li>
                                <li><a href={this.props.urlPath}>{this.props.breadcrumbItem}</a></li>
                                <li><a href={this.props.urlPath1 ? this.props.urlPath1 : ""}>{this.props.breadcrumbItem1 ? this.props.breadcrumbItem1 : ""}</a></li>
                            </ul>
                        </div>

                    </div>
                    <div className="col-md-6">
                        <div className="AccountExpiry">
                            <p className='last-login-font'>
                                Last Login - {this.state.Logintime}
                            </p>
                        </div>
                    </div>


                </div>
            </React.Fragment>
        );
    }
}

export default Breadcrumbs;