import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Notiflix from "notiflix";
import React, { Component } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Searchicon from "../../assets/images/dashboardimages/search.png";
import { MDBDataTable, MDBTableBody, MDBCol, MDBTableHead } from "mdbreact";
import { FaEdit } from "react-icons/fa";
import PostApiCall from "../../Api";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "../Authentication/login.css";
class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: null,
            ShortDescription: "",
            Status: "No",
            Title: "",
            CardTitle: "",
            CardDetails: "",
            TableData: [],
            Enquirydata: [],
            searchInput: "",
            actionLink: "",
        };
    }
    componentDidMount() {
        Notiflix.Loading.Init({
            svgColor: "#777f80",
        });
        Notiflix.Loading.Dots("Please wait...");
        PostApiCall.postRequest(
            {
                whereClause: ` `
            },

            "GetContact"
        ).then((results) =>
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    if (obj.data.length > 0) {
                        this.setState({
                            TableData: obj.data
                        })
                    }
                    Notiflix.Loading.Remove();
                }
            })
        );
        PostApiCall.postRequest(
            {
                whereClause: ` `
            },

            "GetEnquires"
        ).then((results) =>
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    if (obj.data.length > 0) {
                        this.setState({
                            Enquirydata: obj.data
                        })
                    }
                    Notiflix.Loading.Remove();
                }
            })
        );
    }

    onPostData = () => {
        Notiflix.Loading.Dots("Please wait...");
        PostApiCall.postRequest(
            {
                id: this.state.id,
                title: this.state.CardTitle,
                details: this.state.CardDetails,
                heading: this.state.Title,
                status: this.state.Status,
                actionLink: this.state.actionLink
            },
            "UpdateContact"
        ).then((res) =>
            res.json().then((obj1) => {
                if (res.status == 200 || res.status == 201) {
                    Notiflix.Notify.Success("Details Successfully Saved");
                    PostApiCall.postRequest(
                        {
                            whereClause: ` `
                        },

                        "GetContact"
                    ).then((results) =>
                        results.json().then((obj) => {
                            if (results.status == 200 || results.status == 201) {
                                if (obj.data.length > 0) {
                                    this.setState({
                                        TableData: obj.data
                                    })
                                }
                                Notiflix.Loading.Remove();
                            }
                        })
                    );
                    Notiflix.Loading.Remove();
                } else {
                    Notiflix.Loading.Remove();
                    Notiflix.Notify.Failure(obj1.data);
                }
            })
        );

    };
    seachBarHandler = (e) => {
        this.setState({ ...this.state, searchInput: e.target.value });
    };
    capitalizeWords = (str) => {
        return str
            .toLowerCase()
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
    };
    render() {
        const data = {
            columns: [
                {
                    label: "S.No.",
                    field: "serial",
                    sort: "disabled",
                    width: 150,
                },
                {
                    label: "Card title",
                    field: "Cardtitle",
                    sort: "disabled",
                    width: 150,
                },
                {
                    label: "Details",
                    field: "details",
                    sort: "disabled",
                    width: 150,
                },
                {
                    label: "Action link",
                    field: "actionlink",
                    sort: "disabled",
                    width: 150,
                },
                {
                    label: "Show on website",
                    field: "status",
                    sort: "disabled",
                    width: 150,
                },
                {
                    label: "Action",
                    field: "action",
                    sort: "disabled",
                    width: 100,
                },
            ],
            rows: this.state.TableData != "undefined" ? this.state.TableData
                .map((data, i) => {
                    return {
                        serial: (i + 1),
                        Cardtitle: (data.fld_title),
                        details: (data.fld_answer),
                        actionlink: (data.fld_actionlink),
                        status: (data.fld_status),
                        action: (
                            <div className="text-center actionuser d-flex fs-2 justify-content-center pl-3">
                                <div
                                    style={{ fontSize: "25px", width: '2rem', cursor: "pointer" }}
                                    onClick={() => {
                                        this.setState({
                                            Title: data.fld_heading,
                                            Status: data.fld_status,
                                            id: data.fld_id,
                                            CardTitle: data.fld_title,
                                            CardDetails: data.fld_answer,
                                            actionLink: data.fld_actionlink
                                        })
                                    }}
                                >
                                    <FaEdit />

                                </div>
                            </div>
                        ),
                    };
                }) : "",

        }
        const EnquiryTable = {
            columns: [
                {
                    label: "S.No.",
                    field: "serial",
                    sort: "disabled",
                    width: 75,
                },
                {
                    label: "Company Name",
                    field: "CompanyName",
                    sort: "disabled",
                    width: 600,
                },
                {
                    label: "Customer Name",
                    field: "CustomerName",
                    sort: "disabled",
                    width: 200,
                },
                {
                    label: "Contact",
                    field: "Contact",
                    sort: "disabled",
                    width: 100,
                },
                {
                    label: "Email",
                    field: "Email",
                    sort: "disabled",
                    width: 100,
                },
                {
                    label: "Message",
                    field: "Message",
                    sort: "disabled",
                    width: 300,
                },
            ],
            rows: this.state.Enquirydata.filter((data) => {
                if (this.state.searchInput == "") {
                    return data;
                }
                if (
                    this.state.searchInput !== "" &&
                    data.fld_name
                        .toLowerCase()
                        .includes(this.state.searchInput.toLowerCase())
                ) {
                    return data;
                }
                if (
                    this.state.searchInput !== "" &&
                    data.fld_company
                        .toLowerCase()
                        .includes(this.state.searchInput.toLowerCase())
                ) {
                    return data;
                }
                if (
                    this.state.searchInput !== "" &&
                    data.fld_email
                        .toLowerCase()
                        .includes(this.state.searchInput.toLowerCase())
                ) {
                    return data;
                }
                if (
                    this.state.searchInput !== "" &&
                    data.fld_contact
                        .toLowerCase()
                        .includes(this.state.searchInput.toLowerCase())
                ) {
                    return data;
                }
            }).map((data, i) => {
                return {
                    serial: (i + 1),
                    CompanyName: data.fld_company,
                    CustomerName: data.fld_name,
                    Contact: data.fld_contact,
                    Email: data.fld_email,
                    Message: data.fld_message,
                };
            }),
        };
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            breadcrumbItem={this.capitalizeWords(
                                window.location.pathname.replace("/", "")
                            )
                                .replace("-", " ")
                                .replace("_", " ")}
                            urlPath={"/contact"}
                        />
                        <Row className='px-2'>
                            <Col xl="12">
                                <Card
                                    className={"overflow-hidden " + this.state.DisplayVariant}
                                >
                                    <div className="varadatech-bg-soft">
                                        <Row>
                                            <Col xs="4">
                                                <div className="varadatech-text p-3">
                                                    <h5 className="varadatech-text mb-0">Enquiries</h5>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>

                                    <CardBody className="py-1 my-1">

                                        <Col>
                                            <MDBCol
                                                md="12"
                                                style={{
                                                    marginBottom: "15px",
                                                    marginTop: "15px",
                                                }}
                                                className="px-0"
                                            >
                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend">
                                                        <span
                                                            className="input-group-text"
                                                            id="basic-addon1"
                                                        >
                                                            <span className="">
                                                                <img
                                                                    src={Searchicon}
                                                                    alt="block user"
                                                                    className="btnicons"
                                                                ></img>
                                                            </span>
                                                        </span>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Type the search phrase you wish to search within the grid"
                                                        aria-label="Search"
                                                        aria-describedby="basic-addon1"
                                                        onChange={(e) => this.seachBarHandler(e)}
                                                        value={this.state.searchInput}
                                                    />
                                                </div>
                                            </MDBCol>
                                            <MDBDataTable
                                                hover
                                                // scrollY
                                                striped
                                                bordered
                                                data={EnquiryTable}
                                                seachTop={false}
                                            // entriesOptions={[25, 50, 100]}
                                            >
                                                <MDBTableHead columns={EnquiryTable.columns} />
                                                <MDBTableBody rows={EnquiryTable.rows} />
                                            </MDBDataTable>
                                        </Col>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row className='px-2'>
                            <Col xl="12">
                                <Card
                                    className={"overflow-hidden " + this.state.DisplayVariant}
                                >
                                    <div className="varadatech-bg-soft">
                                        <Row>
                                            <Col xs="4">
                                                <div className="varadatech-text p-3">
                                                    <h5 className="varadatech-text mb-0">Contact</h5>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>

                                    <CardBody className="py-1 my-1">
                                        <div className={"form-group row px-2"}>
                                            <div className='col-6 mb-2'>
                                                <label htmlFor="">
                                                    Section heading*
                                                </label>
                                                <input
                                                    type="text"
                                                    id=""
                                                    className="form-control"
                                                    value={this.state.Title}
                                                    onChange={(text) => {
                                                        this.setState({
                                                            Title: text.target.value,
                                                        });
                                                    }}
                                                />
                                            </div>
                                            <div className='col-6 mb-2'>
                                                <label htmlFor="">
                                                    Card title*
                                                </label>
                                                <input
                                                    type="text"
                                                    id=""
                                                    className="form-control"
                                                    value={this.state.CardTitle}
                                                    onChange={(text) => {
                                                        this.setState({
                                                            CardTitle: text.target.value,
                                                        });
                                                    }}
                                                />
                                            </div>
                                            <div className='col-6 mb-2'>
                                                <label htmlFor="">
                                                    Card details*
                                                </label>
                                                <input
                                                    type="text"
                                                    id=""
                                                    className="form-control"
                                                    value={this.state.CardDetails}
                                                    onChange={(text) => {
                                                        this.setState({
                                                            CardDetails: text.target.value,
                                                        });
                                                    }}
                                                />
                                            </div>
                                            <div className='col-6 mb-2'>
                                                <label htmlFor="">
                                                    Card action link
                                                </label>
                                                <input
                                                    type="text"
                                                    id=""
                                                    className="form-control"
                                                    value={this.state.actionLink}
                                                    onChange={(text) => {
                                                        this.setState({
                                                            actionLink: text.target.value,
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 mt-3">
                                            <div className="form-group">
                                                <label htmlFor="sw-arrows-first-name">
                                                    Show on website
                                                    <span className="mandatory">*</span>
                                                </label>
                                                <br />
                                                <label className="radio-inline">
                                                    <input
                                                        type="radio"
                                                        name="optradio"
                                                        checked={
                                                            this.state.Status == "Yes"
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={() => {
                                                            this.setState({
                                                                Status: "Yes",
                                                            });
                                                        }}
                                                    />{" "}
                                                    Yes
                                                </label>
                                                <label className="radio-inline upload-card">
                                                    <input
                                                        type="radio"
                                                        name="optradio"
                                                        checked={
                                                            this.state.Status == "No"
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={() => {
                                                            this.setState({
                                                                Status: "No",
                                                            });
                                                        }}
                                                    />{" "}
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-12 text-right">
                                            <button
                                                style={{
                                                    // float: "right",
                                                    // marginTop: "5px",
                                                }}
                                                onClick={() => {
                                                    if (this.state.CardTitle != "") {
                                                        if (this.state.CardDetails != "") {
                                                            if (this.state.Status != "") {
                                                                this.onPostData();
                                                            } else {
                                                                Notiflix.Notify.Failure(
                                                                    "Please Select Status"
                                                                );
                                                            }
                                                        } else {
                                                            Notiflix.Notify.Failure(
                                                                "Please Enter Details"
                                                            );
                                                        }
                                                    } else {
                                                        Notiflix.Notify.Failure(
                                                            "Please Enter Title"
                                                        );
                                                    }
                                                }}
                                                className="btn align-items-center varadatech-btn"
                                            >
                                                Save
                                            </button>
                                            <hr />
                                        </div>
                                        <Col>
                                            <MDBDataTable
                                                hover
                                                // scrollY
                                                striped
                                                bordered
                                                data={data}
                                                seachTop={false}
                                            // entriesOptions={[25, 50, 100]}
                                            >
                                                <MDBTableHead columns={data.columns} />
                                                <MDBTableBody rows={data.rows} />
                                            </MDBDataTable>
                                        </Col>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    </Container>
                </div>
            </React.Fragment>
        );
    }
}
export default Contact;
