import { CKEditor } from '@ckeditor/ckeditor5-react';
import Accordion from 'react-bootstrap/Accordion';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Notiflix from "notiflix";
import React, { Component } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-datepicker/dist/react-datepicker.css";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import PostApiCall from "../../Api";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "../Authentication/login.css";
import createDOMPurify from 'dompurify';
const DOMPurify = createDOMPurify(window)
class AddAbout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ShortDescription: "",
            id: null,
            Status: "false",
            title: "",
            about: [],
        };
    }
    componentDidMount() {
        Notiflix.Loading.Init({
            svgColor: "#777f80",
        });
        Notiflix.Loading.Dots("Please wait...");
        PostApiCall.postRequest(
            {
                whereClause: ""
            },

            "GetAboutUs"
        ).then((results) =>
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    if (obj.data.length != 0) {
                        this.setState({
                            about: obj.data,
                        });
                        Notiflix.Loading.Remove();
                    }
                }
                Notiflix.Loading.Remove();
            })
        );
    }

    onPostData = () => {
        Notiflix.Loading.Dots("Please wait...");
        PostApiCall.postRequest(
            {
                id: this.state.id,
                description: this.state.ShortDescription,
                title: this.state.title,
                status: this.state.Status,
            },
            "UpdateAboutUs"
        ).then((Response) =>
            Response.json().then((obj1) => {
                if (Response.status == 200 || Response.status == 201) {
                    Notiflix.Notify.Success("Details Successfully Saved");
                    Notiflix.Loading.Remove();
                } else {
                    Notiflix.Loading.Remove();
                    Notiflix.Notify.Failure(obj1.data);
                }
            })
        );
    };
    capitalizeWords = (str) => {
        return str
            .toLowerCase()
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
    };
    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            breadcrumbItem={this.capitalizeWords(
                                window.location.pathname.replace("/", "")
                            )
                                .replace("-", " ")
                                .replace("_", " ")}
                            urlPath={"/about"}
                        />
                        <Row className='px-2'>
                            <Col xl="12">
                                <Card
                                    className={"overflow-hidden "}
                                >
                                    <div className="varadatech-bg-soft">
                                        <Row>
                                            <Col xs="4">
                                                <div className="varadatech-text p-3">
                                                    <h5 className="varadatech-text mb-0">About us</h5>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="py-1 my-1">
                                        <div className='mb-2'>
                                            <label htmlFor="">
                                                Title*
                                            </label>
                                            <input
                                                type="text"
                                                id=""
                                                className="form-control"
                                                value={this.state.title}
                                                onChange={(text) => {
                                                    this.setState({
                                                        title: text.target.value,
                                                    });
                                                }}
                                            />
                                        </div>
                                        <div className="Ckeditors">
                                            <label htmlFor="">
                                                Description*
                                            </label>
                                            <CKEditor
                                                className="mb-3"
                                                editor={ClassicEditor}
                                                data={this.state.ShortDescription}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    this.setState({
                                                        ShortDescription:
                                                            data,
                                                    });
                                                }}
                                            />
                                            <div className='row'>
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label htmlFor="sw-arrows-first-name">
                                                            Show on website
                                                            <span className="mandatory">*</span>
                                                        </label>
                                                        <br />
                                                        <label className="radio-inline">
                                                            <input
                                                                type="radio"
                                                                name="optradio"
                                                                checked={
                                                                    this.state.Status == "Yes"
                                                                        ? true
                                                                        : false
                                                                }
                                                                onChange={() => {
                                                                    this.setState({
                                                                        Status: "Yes",
                                                                    });
                                                                }}
                                                            />{" "}
                                                            Yes
                                                        </label>
                                                        <label className="radio-inline upload-card">
                                                            <input
                                                                type="radio"
                                                                name="optradio"
                                                                checked={
                                                                    this.state.Status == "No"
                                                                        ? true
                                                                        : false
                                                                }
                                                                onChange={() => {
                                                                    this.setState({
                                                                        Status: "No",
                                                                    });
                                                                }}
                                                            />{" "}
                                                            No
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <button
                                                        style={{
                                                            float: "right",
                                                            marginTop: "5px",
                                                        }}
                                                        onClick={() => {
                                                            if (this.state.title != "") {
                                                                if (this.state.ShortDescription != "") {
                                                                    if (this.state.Status != "") {
                                                                        this.onPostData();
                                                                    } else {
                                                                        Notiflix.Notify.Failure(
                                                                            "Please Select Status"
                                                                        );
                                                                    }
                                                                } else {
                                                                    Notiflix.Notify.Failure(
                                                                        "Please Enter Description"
                                                                    );
                                                                }
                                                            } else {
                                                                Notiflix.Notify.Failure(
                                                                    "Please Enter Title"
                                                                );
                                                            }
                                                        }}
                                                        className="btn align-items-center varadatech-btn"
                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                            </div>
                                            <div className='mt-3'>
                                                <Accordion defaultActiveKey="1">
                                                    {this.state.about.length != 0 ? this.state.about.map((data) => {
                                                        return (
                                                            <>
                                                                <Accordion.Item eventKey={data.fld_id}>
                                                                    <Accordion.Header>{data.fld_title}</Accordion.Header>
                                                                    <Accordion.Body>
                                                                        <Card>
                                                                            <CardBody>
                                                                                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.fld_answer) }}></div>
                                                                                <div className='d-flex justify-content-end'>
                                                                                    <i
                                                                                        className="fas fa-edit btn"
                                                                                        style={{ fontSize: "25px" }}
                                                                                        onClick={() => {
                                                                                            this.setState({
                                                                                                ShortDescription: data.fld_answer,
                                                                                                title: data.fld_title,
                                                                                                Status: data.fld_status,
                                                                                                id: data.fld_id,
                                                                                            })
                                                                                        }}
                                                                                    ></i>
                                                                                </div>
                                                                            </CardBody>
                                                                        </Card>
                                                                    </Accordion.Body>
                                                                </Accordion.Item>
                                                            </>
                                                        )
                                                    }) : ""}
                                                </Accordion>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}
export default AddAbout;
