import React, { Component } from "react";
import { Row, Col, Card, CardBody, Media } from "reactstrap";
import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import { Link } from "react-router-dom";
import image from "../../assets/images/edit.png";
import createDOMPurify from 'dompurify';
const DOMPurify = createDOMPurify(window)
class Contact extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
		};
	}
	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});
		Notiflix.Loading.Dots("Please wait...");
		PostApiCall.postRequest(
			{
				whereClause: ""
			},

			"GetContact"
		).then((results) =>
			// const objs = JSON.parse(result._bodyText)
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {
					console.log(obj.data)
					this.setState({
						data: obj.data
					})
				}
				Notiflix.Loading.Remove();
			})
		);
	}
	render() {
		return (
			<React.Fragment>
				<Card className="mini-stats-wid">
					<CardBody>

						<Row className="">
							{/* services */}
							<Col md="12">
								<div className="d-flex justify-content-between px-3">
									<h4 className="mb-0 productTitle">
										Contact
									</h4>
									<Link to={`/contact`} className="d-flex justify-content-end mb-2">
										<img src={image} className="edit-img" />
									</Link>
								</div>
								<hr />

								<Row>
									{this.state.data.length != 0 ? this.state.data.map((data) => {
										return (
											<>
												<Col lg={4} className="mb-4">
													<Card className="h-100">
														<CardBody>
															<div className="d-flex justify-content-between">
																<div>
																	<h4>{data.fld_title}</h4>
																	<div>{data.fld_answer}</div>
																</div>
																<img src={data.fld_icon} style={{ width: "100px" }}></img>
															</div>
														</CardBody>
													</Card>
												</Col>

												{/* <h4 className="col-12">{data.fld_heading}</h4> */}
												{/* <hr /> */}
												{/* <div className="col-12" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.fld_description) }} /> */}
											</>
										)
									}) : ""}
								</Row>
							</Col>
						</Row>
					</CardBody>
				</Card>
			</React.Fragment>
		);
	}
}
export default Contact;
