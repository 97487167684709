import imageCompression from "browser-image-compression";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Accordion from 'react-bootstrap/Accordion';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Notiflix from "notiflix";
import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-datepicker/dist/react-datepicker.css";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import PostApiCall from "../../Api";
import ImageUploadIcon from "../../assets/images/Upload.jpg";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Searchicon from "../../assets/images/dashboardimages/search.png";
import { MDBDataTable, MDBCol, MDBTableBody, MDBTableHead } from "mdbreact";
import "../Authentication/login.css";
import GetApiCall from "../../GETAPI";
// import CommentsList from "./CommentsList";
const ImgUpload = ({ onChange, src }) => (
    <label htmlFor="photo-upload" className="custom-file-upload fas">
        <div className="img-wrap obimages img-upload">
            <img htmlFor="photo-upload" src={src} />
        </div>
        <input accept="image/*" id="photo-upload" type="file" onChange={onChange} />
    </label>
);

class Partners extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ShortDescription: "",
            partners: [],
            patnersData: [],
            searchInput: "",
            parnersDataId: "",
            PartnerListId: "",
            name: "",
            Expertise: "",
            HistoryAndVision: "",
            Photo: "",
            Title: "",
            StartDate: "",
            EndDate: "",
            Time: "",
            LongDescription: "",
            outputFileName: "",
            imagePreviewUrl: ImageUploadIcon,
            // ImageApiUrl: "https://demo.globaltrendz.online/nabdapi/UploadBlogImage",
            originalImage: "",
            RandomNumber: Math.floor(100000 + Math.random() * 900000),
            Status: "false",
        };
    }
    componentDidMount() {
        Notiflix.Loading.Init({
            svgColor: "#777f80",
        });
        // console.log(this.props.location.state);
        Notiflix.Loading.Dots("Please wait...");
        PostApiCall.postRequest(
            {
                whereClause: ""
            },

            "GetPartnersList"
        ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj) => {
                Notiflix.Loading.Dots("Please wait...");
                if (results.status == 200 || results.status == 201) {
                    if (obj.data.length != 0) {
                        this.setState({
                            partners: obj.data

                        });
                        Notiflix.Loading.Remove();
                    }
                }
                Notiflix.Loading.Remove();
            })
        );
        PostApiCall.postRequest(
            {
                whereClause: ""
            },

            "GetPartners"
        ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    if (obj.data.length != 0) {
                        this.setState({
                            patnersData: obj.data,
                            Title: obj.data[0].fld_heading,
                            ShortDescription: obj.data[0].fld_description,
                            parnersDataId: obj.data[0].fld_id,
                        });
                        Notiflix.Loading.Remove();
                    }
                }
                Notiflix.Loading.Remove();
            })
        );
    }
    onPostData = () => {
        Notiflix.Loading.Dots("Please wait...");
        PostApiCall.postRequest(
            {
                id: this.state.parnersDataId != "" ? this.state.parnersDataId : null,
                description: this.state.ShortDescription,
                heading: this.state.Title,
            },
            "UpdatePartners"
        ).then((res) =>
            res.json().then((obj1) => {
                if (res.status == 200 || res.status == 201) {
                    Notiflix.Notify.Success("Details Successfully Saved");
                    PostApiCall.postRequest(
                        {
                            whereClause: ""
                        },

                        "GetPartners"
                    ).then((results) =>
                        // const objs = JSON.parse(result._bodyText)
                        results.json().then((obj) => {
                            if (results.status == 200 || results.status == 201) {
                                if (obj.data.length != 0) {
                                    this.setState({
                                        patnersData: obj.data,
                                        Title: obj.data[0].fld_heading,
                                        ShortDescription: obj.data[0].fld_description,
                                        parnersDataId: obj.data[0].fld_id,
                                    });
                                    Notiflix.Loading.Remove();
                                }
                            }
                            Notiflix.Loading.Remove();
                        })
                    );
                    Notiflix.Loading.Remove();
                } else {
                    Notiflix.Loading.Remove();
                    Notiflix.Notify.Failure(obj1.data);
                }
            })
        );
    }
    onPostPartnersList = () => {
        Notiflix.Loading.Dots("Please wait...");
        var login = localStorage.getItem("LoginDetail");
        var details = JSON.parse(login);

        PostApiCall.postRequest(
            {
                id: this.state.PartnerListId != "" ? this.state.PartnerListId : null,
                name: this.state.name,
                status: this.state.Status,
            },
            "UpdatePartnersList"
        ).then((res) =>
            res.json().then((obj1) => {
                if (res.status == 200 || res.status == 201) {
                    Notiflix.Notify.Success("Partner Details Saved Successfully");
                    PostApiCall.postRequest(
                        {
                            whereClause: ""
                        },

                        "GetPartnersList"
                    ).then((results) =>
                        // const objs = JSON.parse(result._bodyText)
                        results.json().then((obj) => {
                            Notiflix.Loading.Dots("Please wait...");
                            if (results.status == 200 || results.status == 201) {
                                if (obj.data.length != 0) {
                                    this.setState({
                                        partners: obj.data

                                    });
                                    Notiflix.Loading.Remove();
                                }
                            }
                            Notiflix.Loading.Remove();
                        })
                    );
                } else {
                    Notiflix.Loading.Remove();
                    Notiflix.Notify.Failure(obj1.data);
                }
            })
        );
    };
    //upload image start
    onUploadImage = () => {
        Notiflix.Loading.Dots("Uploading Image");
        console.log(this.state.outputFileName);
        let response;
        if (this.state.outputFileName != undefined) {
            var options = {
                maxSizeMB: 2,
                maxWidthOrHeight: 1920,
                useWebWorker: true,
            };
            imageCompression(this.state.originalImage, options).then(
                (compressedFile) => {
                    // //
                    const form = new FormData();
                    form.append("file", compressedFile);
                    form.append("foldername", "");
                    form.append(
                        "filename",
                        this.state.RandomNumber + "-" + compressedFile.name
                    );
                    response = fetch(this.state.ImageApiUrl, {
                        method: "POST",
                        body: form,
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            Notiflix.Loading.Remove();
                            //   this.getEventdetails();
                            Notiflix.Notify.Success("image is successfully added");
                        });
                }
            );
        } else {
            Notiflix.Loading.Remove();
            Notiflix.Notify.Failure("Please Upload Image");
        }
    };
    //upload image end
    seachBarHandler = (e) => {
        this.setState({ ...this.state, searchInput: e.target.value });
    };
    capitalizeWords = (str) => {
        return str
            .toLowerCase()
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
    };
    render() {
        const data = {
            columns: [
                {
                    label: "S.No.",
                    field: "serial",
                    sort: "disabled",
                    width: 75,
                },
                {
                    label: "Company Name",
                    field: "name",
                    sort: "disabled",
                    width: 600,
                },
                {
                    label: "Show on website",
                    field: "status",
                    sort: "disabled",
                    width: 200,
                },
                {
                    label: "Action",
                    field: "action",
                    sort: "disabled",
                    width: 100,
                },
            ],
            rows: this.state.partners.filter((data) => {
                if (this.state.searchInput == "") {
                    return data;
                }
                if (
                    this.state.searchInput !== "" &&
                    data.fld_name
                        .toLowerCase()
                        .includes(this.state.searchInput.toLowerCase())
                ) {
                    return data;
                }
                if (
                    this.state.searchInput !== "" &&
                    data.fld_status
                        .toLowerCase()
                        .includes(this.state.searchInput.toLowerCase())
                ) {
                    return data;
                }
            }).map((data, i) => {
                return {
                    serial: (i + 1),
                    name: data.fld_name,
                    status: data.fld_status,
                    action: (
                        <i
                            className="fas fa-edit btn"
                            style={{ fontSize: "25px", marginTop: "-11px" }}
                            onClick={() => {
                                this.setState({
                                    name: data.fld_name,
                                    Status: data.fld_status,
                                    PartnerListId: data.fld_id,
                                })
                            }}
                        ></i>
                    ),
                };
            }),
        };
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            breadcrumbItem={this.capitalizeWords(
                                window.location.pathname.replace("/", "")
                            )
                                .replace("-", " ")
                                .replace("_", " ")}
                            urlPath={"/partners"}
                        />
                        <Row className='px-2'>
                            <Col xl="12">
                                <Card
                                    className={"overflow-hidden " + this.state.DisplayVariant}
                                >
                                    <div className="varadatech-bg-soft">
                                        <Row>
                                            <Col xs="4">
                                                <div className="varadatech-text p-3">
                                                    <h5 className="varadatech-text mb-0">Partners</h5>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>

                                    <CardBody className="py-1 my-1">
                                        <div className={"form-group "}>
                                            <label htmlFor="">
                                                Title*
                                            </label>
                                            <input
                                                type="text"
                                                id=""
                                                className="form-control"
                                                value={this.state.Title}
                                                onChange={(text) => {
                                                    this.setState({
                                                        Title: text.target.value,
                                                    });
                                                }}
                                            />
                                        </div>
                                        <div className="Ckeditors">
                                            <CKEditor
                                                editor={ClassicEditor}
                                                data={this.state.ShortDescription}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    this.setState({
                                                        ShortDescription:
                                                            data,
                                                    });
                                                }}
                                            />

                                        </div>
                                        <div>
                                            <button
                                                style={{
                                                    float: "right",
                                                    marginTop: "5px",
                                                }}
                                                onClick={() => {
                                                    if (this.state.Title != "") {
                                                        if (this.state.ShortDescription != "") {
                                                            this.onPostData();
                                                        } else {
                                                            Notiflix.Notify.Failure(
                                                                "Please Enter Details"
                                                            );
                                                        }
                                                    } else {
                                                        Notiflix.Notify.Failure(
                                                            "Please Enter Heading"
                                                        );
                                                    }
                                                }}
                                                className="btn align-items-center varadatech-btn"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row className="px-2">
                            <Col xl="12">
                                <Card
                                    className={"overflow-hidden " + this.state.DisplayVariant}
                                >
                                    <div className="varadatech-bg-soft">
                                        <Row>
                                            <Col xs="4">
                                                <div className="varadatech-text p-3">
                                                    <h5 className="varadatech-text mb-0">Partners List</h5>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="py-1 my-1">
                                        <Row>
                                            <div className={"form-group col-6 "}>
                                                <label htmlFor="">
                                                    Company Name*
                                                </label>
                                                <input
                                                    type="text"
                                                    id=""
                                                    className="form-control"
                                                    value={this.state.name}
                                                    onChange={(text) => {
                                                        this.setState({
                                                            name: text.target.value,
                                                        });
                                                    }}
                                                />
                                            </div>
                                            <div className="col-5">
                                                <div className="form-group">
                                                    <label htmlFor="sw-arrows-first-name">
                                                        Show on website
                                                        <span className="mandatory">*</span>
                                                    </label>
                                                    <br />
                                                    <label className="radio-inline">
                                                        <input
                                                            type="radio"
                                                            name="optradio"
                                                            checked={
                                                                this.state.Status == "Yes"
                                                                    ? true
                                                                    : false
                                                            }
                                                            onChange={() => {
                                                                this.setState({
                                                                    Status: "Yes",
                                                                });
                                                            }}
                                                        />{" "}
                                                        Yes
                                                    </label>
                                                    <label className="radio-inline upload-card">
                                                        <input
                                                            type="radio"
                                                            name="optradio"
                                                            checked={
                                                                this.state.Status == "No"
                                                                    ? true
                                                                    : false
                                                            }
                                                            onChange={() => {
                                                                this.setState({
                                                                    Status: "No",
                                                                });
                                                            }}
                                                        />{" "}
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-1">
                                                <button
                                                    style={{
                                                        float: "right",
                                                        marginTop: "5px",
                                                    }}
                                                    onClick={() => {
                                                        if (this.state.name != "") {
                                                            this.onPostPartnersList();
                                                        } else {
                                                            Notiflix.Notify.Failure(
                                                                "Please Enter Company Name"
                                                            );
                                                        }
                                                    }}
                                                    className="btn align-items-center varadatech-btn"
                                                >
                                                    Save
                                                </button>
                                            </div>
                                        </Row>

                                        <div>
                                            <MDBCol
                                                md="12"
                                                style={{
                                                    marginBottom: "15px",
                                                    marginTop: "15px",
                                                }}
                                                className="px-0"
                                            >
                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend">
                                                        <span
                                                            className="input-group-text"
                                                            id="basic-addon1"
                                                        >
                                                            <span className="">
                                                                <img
                                                                    src={Searchicon}
                                                                    alt="block user"
                                                                    className="btnicons"
                                                                ></img>
                                                            </span>
                                                        </span>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Type the search phrase you wish to search within the grid"
                                                        aria-label="Search"
                                                        aria-describedby="basic-addon1"
                                                        onChange={(e) => this.seachBarHandler(e)}
                                                        value={this.state.searchInput}
                                                    />
                                                </div>
                                            </MDBCol>
                                            <MDBDataTable
                                                hover
                                                // scrollY
                                                striped
                                                bordered
                                                data={data}
                                                seachTop={false}
                                                entriesOptions={[10, 25, 50, 100]}
                                            >
                                                <MDBTableHead columns={data.columns} />
                                                <MDBTableBody rows={data.rows} />
                                            </MDBDataTable>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    </Container>
                </div>
            </React.Fragment>
        );
    }
}
export default Partners;
