import React from "react";
import { Route, Redirect } from "react-router-dom";
const PrivateRoute = ({
    component: Component,
    layout: Layout,
    ...rest
}) => (
    <Route
        {...rest}
        render={props => {
            if (localStorage.getItem('LoginDetail')) {
                return (
                    <Layout>
                        <Component {...props} />
                    </Layout>
                );

            } else {
                return (
                    <Redirect to="/" />
                )
            }
        }
        }
    />
);
export default PrivateRoute;