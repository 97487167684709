import React, { Component } from "react";
import { Row, Col, Card, CardBody, Media } from "reactstrap";
import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import { Link } from "react-router-dom";
import image from "../../assets/images/edit.png";
import createDOMPurify from 'dompurify';
const DOMPurify = createDOMPurify(window)
class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],

        };
    }
    componentDidMount() {
        Notiflix.Loading.Init({
            svgColor: "#777f80",
        });
        Notiflix.Loading.Dots("Please wait...");
        PostApiCall.postRequest(
            {
                whereClause: ""
            },

            "GetAboutUs"
        ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    console.log(obj.data)
                    this.setState({
                        data: obj.data
                    })
                }
                Notiflix.Loading.Remove();
            })
        );
    }
    render() {
        return (
            <React.Fragment>
                <Card className="mini-stats-wid">
                    <div className="varadatech-bg-soft">
                        <Row>
                            <Col xs="4">
                                <div className="varadatech-text p-3">
                                    <h4 className="varadatech-text mb-0">About</h4>
                                </div>
                            </Col>
                            <Col xs="8">
                                <Link to={`/about`} className="d-flex justify-content-end p-3 ">
                                    <span className="edit-img-about">
                                        <img src={image} className="edit-img px-0 py-1 edit-img-padding" />
                                    </span>
                                </Link>
                            </Col>
                        </Row>
                    </div>
                    <CardBody>
                        <Row>
                            {this.state.data.length != 0 ? this.state.data.map((data) => {
                                return (
                                    <>
                                        <Col lg={4} className="mb-4">
                                            <h4 className="px-3 py-2">{data.fld_title}</h4>
                                            <Card className="dashboard-top-about-section">
                                                <CardBody>
                                                    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.fld_answer) }} />
                                                </CardBody>
                                            </Card>
                                        </Col>

                                    </>
                                )
                            }) : ""}
                        </Row>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}
export default About;
