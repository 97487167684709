// User profile
import UserProfile from "../pages/Authentication/UserProfile";
// Authentication related pages
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
// Inner Authentication
import LockScreen from "../pages/Authentication/auth-lock-screen";
// Dashboard
import Dashboard from "../pages/Dashboard/index";
//Contacts
import ChangePasswordPage from "../pages/Authentication/ChangePassword";
// =======Company And Master========

import AddAbout from "../pages/pages/AddAbout";
import Services from "../pages/pages/Services";
import AddServices from "../pages/pages/AddServices";
import Partners from "../pages/pages/Partners";
import Contact from "../pages/pages/Contact";

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  //profile
  { path: "/profile", component: UserProfile },
  // form
  // new routes
  { path: "/about", component: AddAbout },
  { path: "/services", component: Services },
  { path: "/service/:action", component: AddServices },
  { path: "/partners", component: Partners },
  { path: "/contact", component: Contact },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  // { path: "/forgot-password", component: ForgetPwd },
  { path: "/change-password-screen", component: ChangePasswordPage },
  { path: "/register", component: Register },

  // Authentication Inner
  { path: "/pages-login", component: Login }, //Login1
  { path: "/pages-register", component: Register }, //Register1
  // { path: "/pages-forgot-pwd", component: ForgetPwd }, //Forgetpwd1
  { path: "/auth-lock-screen", component: LockScreen }, //LockScreen
];

export { authProtectedRoutes, publicRoutes };
