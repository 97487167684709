import React, { Component } from "react";
import { Row, Col, Alert, Card, CardBody, Container } from "reactstrap";
// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
// action
import { userForgetPassword } from "../../store/actions";
// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/gt-logo-light.png";
import { FaUserCircle } from "react-icons/fa";
class ForgetPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }
  // handleValidSubmit
  handleValidSubmit(event, values) {
    this.props.userForgetPassword(values, this.props.history);
  }
  render() {
    return (
      <React.Fragment>
        <div className="account-pages">
          <Container fluid className="main-img">
            <div className="logo-content">
              <h1 className="text-black">Welcome to your</h1>
              <h1 className="text-black">website CMS!</h1>
              <h3 className="text-black">Manage your Website Content</h3>
            </div>
            <div className="mt-5 text-center copyright-content">
              <p>
                © {new Date().getFullYear()}{" "}
                <a
                  className="Varada-copyright"
                  // href="https://varadatech.in/"
                  target="_blank"
                >
                  Varada Tech Solutions
                </a>
                . All Rights Reserved.
              </p>
            </div>
            <Row className="justify-content-end card-align">
              <Col md={8} lg={5} xl={4} className="login-card">
                <Card className="overflow-hidden login-card-shadow">
                  <div className="varadatech-bg-soft">
                    {/* <Row>
                      <Col className="col-7">
                        <div className="varadatech-muted-text p-4">
                          <h5 className="varadatech-muted-text">Welcome Back !</h5>
                          <p>Reset Password to continue to varadatech.</p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col>
                    </Row> */}
                  </div>
                  <CardBody className="pt-0 mb-4">
                    <div className="text-center mt-5">
                      {localStorage.getItem("RememberMe") === "true" ?
                        <img src={JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_image} alt="" className="usericon" /> :
                        <FaUserCircle src={localStorage.getItem("ClientDetails") !== null ? JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_image : ""} className="usericon" />
                      }
                    </div>
                    <h1 className="text-center my-4">Forgot Password</h1>
                    <div className="p-2">
                      {this.props.forgetError && this.props.forgetError ? (
                        <Alert color="danger" style={{ marginTop: "13px" }}>
                          {this.props.forgetError}
                        </Alert>
                      ) : null}
                      {this.props.forgetSuccessMsg ? (
                        <Alert color="success" style={{ marginTop: "13px" }}>
                          {this.props.forgetSuccessMsg}
                        </Alert>
                      ) : null}
                      <AvForm
                        className="form-horizontal mt-4"
                        onValidSubmit={this.handleValidSubmit}
                      >
                        <div className="form-group">
                          <AvField
                            name="email"
                            label="Email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            required
                          />
                        </div>
                        <Row className="form-group">
                          <Col className="text-right">
                            <button
                              className="btn varadatech-btn btn-block waves-effect waves-light"
                              type="submit"
                            >
                              Reset
                            </button>
                          </Col>
                        </Row>
                      </AvForm>
                    </div>
                    <div className="mt-5 text-center">
                      <p>
                        Go back to{" "}
                        <Link
                          to="/"
                          className="font-weight-medium text-primary"
                        >
                          Login
                        </Link>{" "}
                      </p>
                    </div>
                  </CardBody>
                </Card>

              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
const mapStatetoProps = state => {
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword;
  return { forgetError, forgetSuccessMsg };
};
export default withRouter(
  connect(mapStatetoProps, { userForgetPassword })(ForgetPasswordPage)
);
