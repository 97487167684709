import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Notiflix from "notiflix";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import PostApiCall from "../../Api";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "../Authentication/login.css";
class AddServices extends Component {
    constructor(props) {
        super(props);
        const { action } = props.match.params;
        this.state = {
            Action: action,
            id: null,
            ShortDescription: "",
            Status: "false",
            image: "",
            imageurl: "http://localhost:3000/images"
        };
    }
    componentDidMount() {
        Notiflix.Loading.Init({
            svgColor: "#777f80",
        });
        const id = JSON.parse(localStorage.getItem("id"))
        if (this.state.Action == "update") {
            Notiflix.Loading.Dots("Please wait...");
            PostApiCall.postRequest(
                {
                    whereClause: `where fld_id = ${id} `
                },
                "GetServices"
            ).then((results) =>
                results.json().then((obj) => {
                    if (results.status == 200 || results.status == 201) {
                        this.setState({
                            ShortDescription: obj.data[0].fld_description,
                            Title: obj.data[0].fld_heading,
                            Status: obj.data[0].fld_status,
                            id: obj.data[0].fld_id,
                            image: obj.data[0].fld_img,
                        })
                    }
                    Notiflix.Loading.Remove();
                })
            );
        }
    }

    onPostData = () => {
        Notiflix.Loading.Dots("Please wait...");
        PostApiCall.postRequest(
            {
                id: this.state.id,
                description: this.state.ShortDescription,
                heading: this.state.Title,
                status: this.state.Status,
                image: this.state.image,
            },
            "UpdateServices"
        ).then((res) =>
            res.json().then((obj1) => {
                if (res.status == 200 || res.status == 201) {
                    Notiflix.Notify.Success("Details Successfully Saved");
                    this.props.history.push("/services")
                    // const id = JSON.parse(localStorage.getItem("id"))
                    // PostApiCall.postRequest(
                    //     {
                    //         whereClause: `where fld_id = ${id} `
                    //     },
                    //     "GetServices"
                    // ).then((results) =>
                    //     results.json().then((obj) => {
                    //         if (results.status == 200 || results.status == 201) {
                    //             this.setState({
                    //                 ShortDescription: obj.data[0].fld_description,
                    //                 Title: obj.data[0].fld_heading,
                    //                 Status: obj.data[0].fld_status,
                    //                 id: obj.data[0].fld_id,
                    //             })
                    //             Notiflix.Loading.Remove();
                    //         }
                    //     })
                    // );
                    Notiflix.Loading.Remove();
                } else {
                    Notiflix.Loading.Remove();
                    Notiflix.Notify.Failure(obj1.data);
                }
            })
        );

    };

    capitalizeWords = (str) => {
        return str
            .toLowerCase()
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
    };
    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            breadcrumbItem={this.capitalizeWords(
                                window.location.pathname.replace("/", "")
                            )
                                .replace("-", " ")
                                .replace("_", " ")}
                            urlPath={"/services"}
                        />
                        <Row className='px-2'>
                            <Col xl="12">
                                <Card
                                    className={"overflow-hidden " + this.state.DisplayVariant}
                                >
                                    <div className="varadatech-bg-soft">
                                        <Row>
                                            <Col xs="4">
                                                <div className="varadatech-text p-3">
                                                    <h5 className="varadatech-text mb-0">{this.state.Action == "update" ? "Update Sevice" : "Add New Service"}</h5>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>

                                    <CardBody className="py-1 my-1">
                                        <div className={"form-group row"}>
                                            <div className='col-6'>
                                                <label htmlFor="">
                                                    Service Heading*
                                                </label>
                                                <input
                                                    type="text"
                                                    id=""
                                                    className="form-control"
                                                    value={this.state.Title}
                                                    onChange={(text) => {
                                                        this.setState({
                                                            Title: text.target.value,
                                                        });
                                                    }}
                                                />
                                            </div>
                                            <div className='col-6' style={{ position: "relative" }}>
                                                <label htmlFor="">
                                                    Service Image
                                                </label>
                                                <input
                                                    id="photo-upload-new"
                                                    type="file"
                                                    className="form-control"
                                                    style={{ display: "block" }}
                                                    // value={this.state.Title}
                                                    onChange={async (e) => {
                                                        const file = e.target.files[0];
                                                        if (
                                                            file.type == "image/png" ||
                                                            file.type == "image/jpg" ||
                                                            file.type == "image/jpeg"
                                                        ) {
                                                            if (file.size < 2000000) {
                                                                const form = new FormData();
                                                                let path = `${Math.floor(
                                                                    Math.random() * 1000000
                                                                )}-${file.name}`;
                                                                form.append("file", file);
                                                                form.append("foldername", "");
                                                                form.append("filename", path);
                                                                Notiflix.Loading.Dots();
                                                                const res = await fetch(
                                                                    "http://localhost:8039/nabdapi/AddImage",
                                                                    { method: "POST", body: form }
                                                                );
                                                                const response = await res.json();
                                                                if (response) {
                                                                    let imagepath = this.state.imageurl + "/" + path;
                                                                    this.setState({
                                                                        image: imagepath
                                                                    })

                                                                    // document
                                                                    //     .querySelector(`#img-${index}`)
                                                                    //     .classList.remove("visually-hidden");
                                                                    // setValue(
                                                                    //     `${item?.fld_config_id}`,
                                                                    //     `https://admin.vahanischolarship.in/Images/FinanceAccounts/${path}`
                                                                    // );
                                                                } else {
                                                                    Notiflix.Loading.Remove();
                                                                    Notiflix.Notify.Failure(
                                                                        "There seems to be an Error. Please retry!"
                                                                    );
                                                                }
                                                                Notiflix.Loading.Remove();
                                                            } else {
                                                                Notiflix.Notify.Failure(
                                                                    "File too large, upload file less than 2 MB."
                                                                );
                                                            }
                                                        } else {
                                                            Notiflix.Notify.Failure(
                                                                "Image should be in jpeg, jpg or png format."
                                                            );
                                                        }
                                                    }}
                                                ></input>
                                                <div style={{ display: this.state.image == "" || this.state.image == null ? "none" : "block" }} className='view-button'>
                                                    <a href={this.state.image} target='blank'>View image</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="Ckeditors">
                                            <CKEditor
                                                editor={ClassicEditor}
                                                data={this.state.ShortDescription}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    this.setState({
                                                        ShortDescription:
                                                            data,
                                                    });
                                                }}
                                            />
                                        </div>
                                        <div className="col-12 mt-3">
                                            <div className="form-group">
                                                <label htmlFor="sw-arrows-first-name">
                                                    Show on website
                                                    <span className="mandatory">*</span>
                                                </label>
                                                <br />
                                                <label className="radio-inline">
                                                    <input
                                                        type="radio"
                                                        name="optradio"
                                                        checked={
                                                            this.state.Status == "Yes"
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={() => {
                                                            this.setState({
                                                                Status: "Yes",
                                                            });
                                                        }}
                                                    />{" "}
                                                    Yes
                                                </label>
                                                <label className="radio-inline upload-card">
                                                    <input
                                                        type="radio"
                                                        name="optradio"
                                                        checked={
                                                            this.state.Status == "No"
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={() => {
                                                            this.setState({
                                                                Status: "No",
                                                            });
                                                        }}
                                                    />{" "}
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <button
                                                style={{
                                                    float: "right",
                                                    marginTop: "5px",
                                                }}
                                                onClick={() => {
                                                    if (this.state.Title != "") {
                                                        if (this.state.ShortDescription != "") {
                                                            if (
                                                                this.state.Status != ""
                                                            ) {
                                                                this.onPostData();
                                                            } else {
                                                                Notiflix.Notify.Failure(
                                                                    "Please Select Status"
                                                                );
                                                            }
                                                        } else {
                                                            Notiflix.Notify.Failure(
                                                                "Please Enter Details"
                                                            );
                                                        }
                                                    } else {
                                                        Notiflix.Notify.Failure(
                                                            "Please Enter Title"
                                                        );
                                                    }
                                                }}
                                                className="btn align-items-center varadatech-btn"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}
export default AddServices;
