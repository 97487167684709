import Notiflix from "notiflix";
import React, { Component } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import PostApiCall from "../../Api";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "../Authentication/login.css";
import { Link } from "react-router-dom";
import createDOMPurify from 'dompurify';
import image from "../../assets/images/edit.png";

const DOMPurify = createDOMPurify(window)
class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }
  componentDidMount() {
    Notiflix.Loading.Dots("Please wait...");
    PostApiCall.postRequest(
      {
        whereClause: ""
      },
      "GetServices"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          console.log(obj.data)
          this.setState({
            data: obj.data
          })
        }
        Notiflix.Loading.Remove();
      })
    );
  }
  capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              breadcrumbItem={this.capitalizeWords(
                window.location.pathname.replace("/", "")
              )
                .replace("-", " ")
                .replace("_", " ")}
              urlPath={"/services"}
            />
            <Row className='px-2'>
              <Col xl="12">
                <Card
                  className={"overflow-hidden " + this.state.DisplayVariant}
                >
                  <div className="varadatech-bg-soft">
                    <Row>
                      <Col xs="4">
                        <div className="varadatech-text p-3">
                          <h5 className="varadatech-text mb-0">Services</h5>
                        </div>
                      </Col>
                      <Col xs="8">
                        <div className="varadatech-text">
                          <Link to="/service/create" className="AddNewBtn btn">Add new service</Link>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="py-1 my-1">
                    <div className="row">
                      {this.state.data.length != 0 ? this.state.data.map((data) => {
                        return (
                          <>
                            <div className="col-12 col-lg-6">
                              <Card style={{ height: "95%" }}>
                                <CardBody>
                                  <Link to={`/service/update`} className="d-flex justify-content-end mb-2" onClick={() => {
                                    localStorage.setItem("id", JSON.stringify(data.fld_id))
                                  }}>

                                    <img src={image} className="edit-img" />
                                  </Link>
                                  <div className="col-6 mb-3">
                                    <img src={data.fld_img} style={{ width: "250px", height: "250px" }} ></img>
                                  </div>
                                  <h4 className="col-12">{data.fld_heading}</h4>
                                  <div className="col-12 text-justify" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.fld_description) }} />
                                </CardBody>
                              </Card>
                            </div>
                          </>
                        )
                      }) : ""}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default Services;
